
const title = 'Frequently Asked Questions (FAQs)'

const list1 = [
    `- How much does it cost to sign up for ALIN?`,
    `- Is my child’s privacy protected?`,
    `- What if my child is great at math?  Does this program still help him/her?`,
    `- What if my child struggles in math?  Does this program really work for kids who hate math?`,

]

const list2 = [
    `- What if my child doesn’t like attention training?`,
    `- How effective is an online math program like ALIN?`,
    `- What if my child doesn’t like the program?`,

]


const questions = [
    {
        title: 'How much does it cost to sign up for ALIN?',
        answer: 'ALIN is forever free for students to use.  Founder of ALIN, Kelvin Lwin, has dedicated his life to attention training and believes every student should have the opportunity to grow and excel in math and attention training.  Because of this belief, Kelvin made a promise to keep the program forever free for users.'
    },
    {
        title: 'Is my child’s privacy protected?',
        answer: 'Your child’s data and identity is completely confidential and protected through ___.'
    },
    {
        title: 'What if my child is great at math?  Does this program still help him/her?',
        answer: 'Absolutely.  The adaptive nature of the program encourages students to continue growing in their mathematical capabilities.  There’s no “grade level” to stop your child from advancing.  When they’re ready to move on to the next concept, they can.  It’s a great program for parents looking to supplement and enrich their child’s mathematical skills.'
    },
    {
        title: 'What if my child struggles in math?  Does this program really work for kids who hate math?',
        answer: 'Yes, attention training is a core competency of the entire program.  It will develop and strengthen your child’s ability to attend to problems they don’t understand at first.  This will help them advance in their understanding of mathematical elements and concepts.  The AI adapts to ensure your child is never bored or frustrated.  Their program is designed specifically to meet your child where he or she is and advance them.'
    },
    {
        title: 'What if my child doesn’t like attention training?',
        answer: 'Children often don’t like things they don’t understand.  attention training is a life-long practice, but the earlier a child begins, the better they get at it.  Much like understanding math concepts, children need exposure to attention training.  Once they begin their practice, they’ll appreciate how it gives them the tools to self-regulate.'
    },
    {
        title: 'How effective is an online math program like ALIN?',
        answer: 'ALIN helps your child feel confident in math while advancing their overall understanding. Our adaptive approach personalizes math instruction and attention training for your child.  As a result of this individualized approach, it enhances his or her math skills and overall confidence.'
    },
    {
        title: 'What if my child doesn’t like the program?',
        answer: 'The program’s completely free, so if your child doesn’t like it or find it helpful, they can always stop using it.  Many users enjoy the gamification and avatar experience, and they feel like they’re not even learning because they’re having so much fun.  But, if your child doesn’t like the program, he or she can easily unsubscribe without any penalty.'
    },
]

const FAQs = {
    title,
    list1,
    list2,
    questions
}

export { FAQs };


























