import { Routes, Route, useLocation } from "react-router-dom";
import { Navbar } from "./components/Navbar";

import {
  HomePage,
  HowAlinWorksPage,
  ThePlatformPage,
  AuthPage,
  NotFoundPage,
  SingUp,
  ContactPage,
  FaqPage,
} from "./pages";
import { Footer } from "./components/Footer";
import { Wrapper } from "./components/Wrapper";
import AssistivePage from "./pages/AlinPages/assistive";
import LearningPage from "./pages/AlinPages/learning";
import IntelligencePage from "./pages/AlinPages/intelligence";
import NavigatorPage from "./pages/AlinPages/navigator";
import MeditationPage from "./pages/MeditationPages";
import FounderPage from "./pages/FounderPages";
import ResearchPage from "./pages/ResearchPage";
import { useEffect } from "react";
import AboutUsPage from "./pages/AboutUsPage";
import MeetCoFounder from "./pages/MeetCoFounder";

const RedirectComponent:  React.FC<{ from: string; to: string }> = ({ from, to }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === from) {
        window.location.replace(to);
    }
  }, []);

  return <>
    <div style={{width:'100vw', height:'100vh', position:'fixed', top: 0, left:0, zIndex: 9999, backgroundColor: '#fff'}}>
      <h5 style={{textAlign: 'center', marginTop: '50vh'}}>Loading...</h5>
    </div>;
  </> 
};



function App() {
  useEffect(() => {
    const divHidden = document.getElementById("loom-companion-mv3") as HTMLElement;
    if (divHidden) {
      divHidden.style.display = "none";
    }
  }, []);
  
  return (
    <div>
      <Wrapper />
      <Navbar />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="howalinworks" element={<HowAlinWorksPage />} />
        <Route path="theplatform" element={<ThePlatformPage />} />
        <Route path="login" element={<AuthPage />} />
        <Route path="getstarted" element={<SingUp />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="faqPage" element={<FaqPage />} />
        <Route path="meet-co-founder" element={<MeetCoFounder />} />
        <Route path="assistive" element={<AssistivePage />} />
        <Route path="learning" element={<LearningPage />} />
        <Route path="intelligence" element={<IntelligencePage />} />
        <Route path="navigator" element={<NavigatorPage />} />
        <Route path="attentiontraining" element={<MeditationPage />} />
        <Route path="meet-the-founder" element={<FounderPage />} />
        <Route path="ai-research" element={<ResearchPage />} />
        <Route path="about-us" element={<AboutUsPage />} />
        <Route  path="/student" element={<RedirectComponent from="/student" to="https://app.alin.ai/register?type=Student" />} />
        <Route  path="/patron" element={<RedirectComponent from="/patron" to="https://app.alin.ai/register?type=Patron" />} />
        <Route  path="/parent" element={<RedirectComponent from="/parent" to="https://app.alin.ai/register?type=Parent" />} />
        
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
      <Wrapper />
    </div>
  );
}




export default App;
