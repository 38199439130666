import { Link } from "react-router-dom";
import Logo from "../../assets/images/logos/all-logo.png";
import { menuFooter } from "../../data/menuFooter";
import { Button } from "../Button";
import { LinkList } from "../LinkList";
import styles from "./styles.module.css";

function Footer() {
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.footLeft}>
          <div className="">
            <Link
              to="/"
              className={styles.Logo}
              /* onClick={setIsNavShowing(false)} */
            >
              <img src={Logo} height={42} alt="Logo Alin With Kelvin" />
            </Link>
          </div>
          <p>
            Alin it means “The Light” in Burmese and stands for Assistive
            Learning Intelligence Navigator.
          </p>
          <Button
            className={styles.authBtnGetStarted}
            name="Get started"  
            to='https://app.alin.ai/register'
            actions={true}
          />
        </div>
        {/* <LinkList
          listItem={menuFooter.AboutUs}
          typeList="ul"
          className={styles.list}
          title="About us"
          to=""
        /> */}
        <div></div>
        {/* <ul className={styles.list}>
          <a  target="_blank" href="https://docs.google.com/file/d/1k0V4ouv8hPVyvwxhuxF7-tGTuLQpXTsR/edit?usp=docslist_api&filetype=msword">
            <strong style={{margin: '0 40px' }}>About us</strong>
          </a>
        </ul> */}
        <LinkList
          listItem={menuFooter.AboutUs}
          typeList="ul"
          className={styles.list}
          title="About us"
          to="/about-us"
        />
         
        <LinkList
          listItem={menuFooter.meetTheFounder}
          typeList="ul"
          className={styles.list}
          title="Meet The Founder"
          to="/meet-the-founder"
        />


        <LinkList
          listItem={menuFooter.helpAndSupport}
          typeList="ul"
          className={styles.list}
          title="Help & Support"
          to="/contact"
        />
{/*
        <LinkList
          listItem={menuFooter.social}
          typeList="ul"
          className={styles.list}
          title="Social"
          to=""
        /> */}
        <LinkList
          listItem={menuFooter.privacyAndTerms}
          typeList="ul"
          className={styles.list}
          title="Privacy  & Terms"
          to=""
        />
        {/* <a href="https://www.facebook.com/">Facebook</a> */}
      </div>
      <div className={styles.textReserved}>
        © 2023 ALIN. All rights reserved. 
      </div>
    </footer>
  );
}
export { Footer };
