
import { Heading } from '../../components/Heading';
import styles from "./styles.module.css";
import { Button } from '../../components/Button';

import Uc from "../../assets/images/ucb.png";
import Mit from "../../assets/images/Mit.png";
import Ze from "../../assets/images/Zhe.png";
import SMU from "../../assets/images/SMU.png";
import Diagrama from "../../assets/images/diagrama.jpg";
function ResearchPage() {
  
  const title = "Research Background & Motivation";
  return (
    <main className='meditationPage'>
      <div className={styles.container}>
        <Heading
          typeHeading='h1'
          title={title}
        />
        <section className={styles.section}>

          <p>Most existing in-classroom K-12 education programs across the globe do not cater to individual students' learning needs. They put every student on the same learning path despite differences in students’ learning skills and abilities.
          </p><br />
          <p>Our research motivation aims at improve Math learning outcomes for K12 students by providing students with engaging gamified lessons and AI-based personalized learning paths in a scalable and accessible manner. Students are assessed continuously during the learning process (formative assessment) instead of tests at the end of the lessons. Their learning path (subsequent lessons) is created dynamically by the AI based on the student’s assessment at that time.
          </p><br />
          <p>Although test scores are most commonly used as the main metric to track the results of learning in EdTech solutions. We seek to provide better assessment driven by learning data. In a data-driven approach, we may redefine student success away from solely using test scores to whole child development approach. This goal can be better achieved by our live learning platform, ALIN.ai, that provides free math lessons with meditation-based attention training interspersed throughout the lessons.
          </p><br />
          <p>In the area of educational data mining, there are some existing challenges[1]. The social challenges typically include student’s performance analysis and privacy protection. By analyzing learning data, the performance of a student should be assessed and judged whether he should go on learning harder contents. Meanwhile, the private information related to the student should be protected. For the technological challenges, we should considered some practical issues such as the deployment of the system, data collection, data preprocessing and so on.
          </p><br />
          <p>
            In our approach, the learning behavior of students is observed and encoded. Based on the feature engineering, we have built a generalized model that can fit different students. Moreover, we aim to train a model to predict the performance of students and to try to explain how the model works.
          </p><br />
          <p>The following figure shows the framework of a baseline learning topic recommender system using the predictive model on student score for personalized adaptation. We explored various existing machine learning models, including, regression model and GBRT model, and we are also testing the privacy-preserving version of our models, such as federated learning and secure multiparty computation.
          </p>
          
          <img src={Diagrama} className="imagen" alt="Reference." />
          <p><b>Reference:</b></p>
          <p>
            [1]Ang, K. L. M., Ge, F. L., & Seng, K. P. (2020). Big educational data & analytics: Survey, architecture and challenges. IEEE access, 8, 116392-116414.
          </p>
          <br /><br />
          <h3>Our Contributions:</h3>

          <p><b>1.- Public Available Data Set </b></p>

          <p>Citation: "Guijia He, Chengwei Huang, Steven Yang, Kelvin Lwin, Ran Ju, Yuanmi Chen, Xiaoming Zhu, October 5, 2022, "ALIN Open Dataset for Math Adaptive Learning", IEEE Dataport, doi: https://dx.doi.org/10.21227/ezmt-dj40."
          </p>
          <p>To predict Test Results, in the form of averaged correctness, averaged timespent in the test, based only on the learning history (learning  behavior records)
          </p>
          <p>The raw data is provided by ALIN.ai where a large number of students participated in math learning and tests, online.
          </p>
          <p>The feature constructed from the raw data is achieved by applying statistic functionals to the backend data sheets where learning behavior is recorded, such as 'points earned' in a 'learning session'. The final cohort we build from this learning senario consists of the predicting target: averaged correctness, and the averaged timespent, and the input features (43 dimensions). The input features consist of two parts, one contains information(5 dimensions) on the test itself, e.g. the difficulty; the other contains the rest 38 dimensions of features.
          </p>
          <br /><br />

          <p><b>Recommended Readings:</b></p>
          <ol className='listRound'>
            <li>Romero, C., & Ventura, S. (2010). Educational data mining: a review of the state of the art. IEEE Transactions on Systems, Man, and Cybernetics, Part C (Applications and Reviews), 40(6), 601-618.</li>
            <li>Baker, R. S. (2019). Challenges for the future of educational data mining: The Baker learning analytics prizes. Journal of Educational Data Mining, 11(1), 1-17.</li>
            <li>Ang, K. L. M., Ge, F. L., & Seng, K. P. (2020). Big educational data & analytics: Survey, architecture and challenges. IEEE access, 8, 116392-116414.</li>
            <li>Baker, R. S. (2019). Challenges for the future of educational data mining: The Baker learning analytics prizes. Journal of Educational Data Mining, 11(1), 1-17.</li>
            <li>Pai, K. C., Kuo, B. C., Liao, C. H., & Liu, Y. M. (2021). An application of Chinese dialogue-based intelligent tutoring system in remedial instruction for mathematics learning. Educational Psychology, 41(2), 137-152.</li>
            <li>Alkhatlan, A. and Kalita, J., 2018. Intelligent tutoring systems: A comprehensive historical survey with recent developments. arXiv preprint arXiv:1812.09628.</li>
          </ol>
        </section>
        <section className='LogoSection'>
          <div>
            <h3>Related Institutes</h3>
          </div>
          <div>
            <div>
              <p>UC Berkeley (Alumni).</p>
              <Button
                className={styles.link}
                name="https://www.berkeley.edu/"
                to='https://www.berkeley.edu/'
                actions={true}
              />
              <img src={Uc} width={200} alt="UC Berkeley (Alumni)" />
            </div>
            <div>
              <p>MIT (Alumni).</p>
              <Button
                className={styles.link}
                name="https://web.mit.edu/"
                to='https://web.mit.edu/'
                actions={true}
              />
              <img src={Mit} width={200} alt="MIT (Alumni)." />
            </div>
            <div>
              <p>Zhejiang Laboratory (Co-author Institute).</p>
              <Button
                className={styles.link}
                name="https://en.zhejianglab.com/"
                to='https://en.zhejianglab.com/'
                actions={true}
              />
              <img src={Ze} width={200} alt="Zhejiang Laboratory (Co-author Institute)." />
            </div>
            <div>
              <p>Singapore Management University (Co-author Institute).</p>
              <Button
                className={styles.link}
                name="https://www.smu.edu.sg/"
                to='https://www.smu.edu.sg/'
                actions={true}
              />
              <img src={SMU} width={200} alt="Singapore Management University (Co-author Institute)." />
            </div>
          </div>

        </section>
      </div>
    </main>

  );
}

export default ResearchPage;
