import styles from "./styles.module.css";
import { Heading } from "../../components/Heading";
import { intelligenceTexto } from "../../data/cards";
import { Image } from "../../components/Image";
function IntelligencePage() {
    return (
        <main>
            <div className={styles.container}>
                <Heading
                    typeHeading='h1'
                    title={intelligenceTexto.caption}
                />
                <section className={styles.section}>


                    <div className={styles.sectionImage}>
                        <Image
                            imageUrl={intelligenceTexto.image}
                            alt={`image ${intelligenceTexto.caption}`}

                        /></div>
                    <p>Current EdTech programs aren’t intelligent-they’re arranged in a one-size-fits-all singular sequence.</p>

                    <p>When the user gets a math problem wrong on one of these programs, he or she must complete an arbitrary amount of similar problems to continue.</p>

                    <p>Your child, and every other child, shuts down with this punitive reinforcement.</p>

                    <p>Instead of overemphasizing and disengaging the learner through repetitive drills, our intelligence determines different paths for your child to take.</p>

                    <p>Maybe your child was distracted or feeling stressed.</p>
                    <p>Our intelligence will recommend a calming exercise to increase focus.</p>

                    <p>Maybe your child needs to review the materials.</p>
                    <p>Our intelligence will share a new resource in the form of a video or examples to break down core concepts.</p>

                    <p>Instead of forcing your child to look at only their mistakes in a prescribed, sequenced way, ALIN intelligence provides choices.</p>

                    <p>ALIN’s intelligence program is like having a one-on-one tutor by your child’s side, providing support in real time and giving your child the most cutting-edge math education.</p>


                </section>
            </div>

        </main>
    );
}

export default IntelligencePage;
