
const AboutUs = [
  {
    name: '',
    path: ''
  }
  // {
  //   name: "Mission",
  //   path: "/Mission"
  // },
  // {
  //   name: "Vission",
  //   path: "/Vission"
  // },
  // {
  //   name: "Our Philosophy",
  //   path: "/Our Philosophy"
  // },
  // {
  //   name: "Introducing Assistive",
  //   path: "/Introducing Assistive"
  // },
  // {
  //   name: "Intelligence (AI)",
  //   path: "/Intelligence (AI)"
  // },
  // {
  //   name: "The Birth of ALIN ",
  //   path: "/The Birth of ALIN "
  // },
  // {
  //   name: "Meet the Founder ",
  //   path: "/Meet the Founder "
  // },
]

const helpAndSupport = [
  {
    name: "ALIN FAQs ",
    path: "/FaqPage"
  },
]

const meetTheFounder = [
  {
    name: "Meet Samuel Davy",
    path: "/meet-co-founder"
  },
]


const social = [
  {
    name: '',
    path: ''
  }
  // {
  //   name: "Blog ",
  //   path: "/Blog "
  // },
  // {
  //   name: "Facebook ",
  //   path: "/https://www.facebook.com/"
  // },
  // {
  //   name: "Twitter ",
  //   path: "/https://twitter.com/?lang=en "
  // },
]

const privacyAndTerms = [
  {
    name: '',
    path: ''
  }
  // {
  //   name: "Terms ",
  //   path: "/Terms "
  // },
  // {
  //   name: "Privacy ",
  //   path: "/Privacy "
  // }
]

const menuFooter = {
  AboutUs,
  helpAndSupport,
  social,
  privacyAndTerms,
  meetTheFounder
}

export { menuFooter }