import { HeroBanner } from "../../components/HeroBanner";

import styles from "./styles.module.css";
import { child } from '../../data/sectionChild';
import { Image } from '../../components/Image';
import { List } from '../../components/List';
import { Slider } from "../../components/Slider";
import { Cards } from "../../components/Cards";
import HowAlinWorksPage from "../HowAlinWorksPage";

function HomePage() {
  return (
    <>
      <HeroBanner />
 
      <Slider />
      <main>
        <div className={styles.child}>
          <ul>
            {child.texts.map( (text,index) => (
              <li key={`child ${index}`}>
                {text.content}
              </li>
            ))}
          </ul>
          <div className={styles.listChild}>
            <Image 
              imageUrl={child.image}
              alt='person observing'
            />
            <List 
              listItem={child.list.item}
              typeList='ul'
              title={child.list.title}
              className={styles.listItemChild}
            />
          </div>
          <p>
            {child.list.textEnd }
          </p>
        </div>

      </main>
      <div className={styles.howAlinWorkContent}>
        <Cards />
      </div>
      <div className={styles.howAlinWorkContent}>
        <HowAlinWorksPage />
      </div>
    </>
  );
}

export default HomePage;
