import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import styles from './styles.module.css';
import { useDispatch } from 'react-redux';
import { actions } from '../../actions';
import { useAppSelector } from '../../reducers/hooks';


const days = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
const months = ['january','february','march','april','may','june','july','august','september','october','november','december',]
const years = [1994,1995,1996,1997,1998,1999,2000,2001,2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015]
function SingUp () {
  const navigate = useNavigate();
  const registerUser = useAppSelector(state => state.register);
  const dispatch = useDispatch();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | 
    HTMLSelectElement>) =>{
    const content =event.target;
    dispatch(actions.registerOnChange(content))
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement >) => {
    event.preventDefault();
    dispatch(actions.reset())
    alert(`Hello user ${registerUser.userName} has just successfully registered .`)
    navigate("/login")
  }
  console.log(registerUser)
  return(
  <form onSubmit={handleSubmit}>
    <div className={styles.container}>
      <Heading 
        title='Sing Up'
        typeHeading= 'h4'
      />
      <fieldset>
        <span>What is your date of birth?</span>
        <div>
          <select name='month' id="months" onChange={handleChange} value={registerUser.month}>
            {months.map((months) => (
              <option key={months} value={months}>{months}</option>
            ))}
          </select>
          <select name='day' id="days" onChange={event => event} value={registerUser.day}>
            {days.map((day) => (
              <option key={day} value={day}>{day}</option>
            ))}
          </select>
          <select name='year' id="years" onChange={handleChange} value={registerUser.year}>
            {years.map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
        </select>
        </div>
      </fieldset>
      <fieldset>
        <label htmlFor="userName" >
          Choose a username (For safety, don't use your real name)
        </label>
        <input type='text' id='userName' name='userName' onChange={handleChange} value={registerUser.userName}/>
      </fieldset>
      <fieldset>
        <label htmlFor="email" >
          Add your parents’ email (We need to notify your parent about your account)
        </label>
        <input type='email' id='email' name='email' onChange={handleChange} value={registerUser.email}/>
      </fieldset>
      <fieldset>
        <label htmlFor="password" >
          Create a Password
        </label>
        <input type='password' id='password' name='password' onChange={handleChange} value={registerUser.password}/>
      </fieldset>
      <button type="submit">Sing Up</button>
      <Link 
        to='/login'
        className={styles.text}
      >
        I have an account
      </Link>
      <Link
        to='/'
        className={styles.terms}
      >
        By signing up Alin, you agree to our <strong>Terms</strong> and <strong>Privacy Policy</strong>.
      </Link>

    </div>
  </form>

  )
}

export default SingUp;