
import { Heading } from '../../components/Heading';
import styles from "./styles.module.css";

import attention from "../../assets/images/meditation.jpg";
function MeditationPage() {
  const title = "Attention Training";
  return (
    <main className='meditationPage'>
      <div className={styles.container}>
        <Heading
          typeHeading='h1'
          title={title}
        />
        <section className={styles.section}>
          <h3 className='text-center'>How to maintain flow in work and life</h3>

          <h4>What is attention training?</h4>

          <p>Attention training, like the name suggests, is a way to strengthen and improve the quality and stamina of our attention.  This will allow students to reach an optimal flow state in their learning and become more productive and motivated.  But what is flow?</p>
          <p>The state of flow is easiest explained through sports, music, or art.  It is when the athlete or artist is “in the zone,” playing their best game or creating their best work.  They are aware of everything around them but at the same time are focused on the task at hand, be it passing a ball or dragging a brush across the canvas.  Many people consider it an out-of-body experience, like they are outside their normal reality.  This state of extreme focus and motivation is what ALIN hopes to help students achieve while studying.  According to Flow, a TedTalk presented by Mihaly Csikszentmihalyi, there are two main pathways to achieve flow, shown below.
          </p>

          <div className='flex-box'>
            <div>
              <img src={attention} className="image_meditation" alt="Attention training." /></div>
            <div>
              <p><b className='color-red'>Path 1: Arousal</b> This is how many people achieve flow during sports.  However, this isn’t sustainable over long periods of time.  Furthermore, this method relies on negative feelings and emotions like worry, anxiety, and apathy.</p>
              <p><b className='color-blue'>Path 2: Control/Relaxation</b>
                The Control method is what ALIN is seeking to guide students along, by controlling relaxation (where students are confident and content) to reach flow.
              </p>
            </div>
          </div>
          <p>Building on confidence, relaxation, and being in control is what will help students achieve an improved learning experience.</p>
        </section>
      </div>
    </main>

  );
}

export default MeditationPage;
