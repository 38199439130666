import { Link } from 'react-router-dom'

type List = Array<{
  name: string
  path: string
}>
interface PropsLinkList {
  listItem:List 
  typeList: string
  className?:string
  title: string
  to: string

}
function LinkList ({listItem,typeList,className,title,to}:PropsLinkList){
  let createListItem = listItem.map(({name,path},index) =>( 
    <li key={`${name}${index}`}>
      <Link 
        to={path}
      >
        {name}
      </Link>
    </li>
    ));
  let createTypeList = typeList === 'ol' ? <ol>{createListItem}</ol>:
                       typeList === 'ul' ? <ul>{createListItem}</ul>:
                       <li>{createListItem}</li>
  return(
    <div className={className}>
      <span>
        <Link to={to}> 
          {title}
        </Link>

      </span>
      {createTypeList}
    </div>
  )
}

LinkList.defaultProps = {
  listItem: [],
  typeList: '',
  className: '',
  title: ''
}

export { LinkList };