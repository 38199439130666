
const title = 'ALIN Platform';

const list1 = [
  'ALIN is a personalized, free online math program that prevents attention scarcity, math anxiety, and frustration.',

  `The artificial intelligence adapts to your child’s way of learning and is in tune to what your child needs in real time, whether it's remediation or enrichment.`

]

const list2 = [

  `Students log in under their unique username and password.`,

  `As they progress through the program, they earn badges and awards.  This increases their motivation.`,

  `Because every learner is unique in how they problem solve, the program quickly adapts based on how your child answers questions.  This personalization encourages and promotes your child to continue working, maintaining their attention and engagement.`,

  `When a question is answered incorrectly, the program adapts to target the misconception in real time, giving your child the ability to understand their learning discrepancy.`,

]

const list3 = [`
  Create your free account now and get started on an inward journey of confidence and success.
  Need to talk more?.
`]

const nameInsert = 'Insert image with platform screenshots'



const AlinPlatform = {
  title,
  list1,
  list2,
  list3,
  nameInsert
}

export { AlinPlatform }


