import {LoginReducerActions,LoginState} from './data'
const INITIAL_STATE : LoginState= {
 userName: '',
 password: '',
 remember: ''
}
const loginReducer = (state=INITIAL_STATE,action:LoginReducerActions) => {
    switch(action.type){
        case '@login/change_value':
          const {inputName, inputValue}= action.payload
          return{
            ...state,
            [inputName]:inputValue
          }
        case '@reset':
          return INITIAL_STATE;
        default:
          return state;
    }
}

export { loginReducer }