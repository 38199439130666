import { Heading } from "../../components/Heading";
import styles from "./styles.module.css";
import {FAQs} from '../../data/FAQs';
import { List } from "../../components/List";

function index() {
  return (
    <main>
      <div className={styles.container}>
        <Heading 
          title={FAQs.title}
          typeHeading='h1'
        />
        <div className={styles.list}>
          <List 
            listItem={FAQs.list1}
            typeList='ul'
          />
          <List 
            listItem={FAQs.list2}
            typeList='ul'
          />
        </div>
        <section className={styles.section}>
          <>
            {FAQs.questions.map((question,index) => (
              <article key={`${question.title}${index}`}>
                <h4>
                  {question.title}
                </h4>
                <p>
                  {question.answer}
                </p>
              </article>
            ))}
          </>
        </section>      
      </div>
    </main>
  )
}

export default index;
