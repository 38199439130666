
interface PropsList {
  listItem: Array<string>
  typeList: string
  className: string
  title: string
}
function List ({listItem,typeList,className,title}:PropsList){
  let createListItem = listItem.map((list,index) =>( 
    <li dangerouslySetInnerHTML={{ __html: list }} key={`${list}${index}`}>
    </li>
    ));
  let createTypeList = typeList === 'ol' ? <ol>{createListItem}</ol>:
                       typeList === 'ul' ? <ul  >{createListItem}</ul>:
                       <li>{createListItem}</li>
  return(
    <div className={className}>
      {title ? <span>{title}</span>: null}
      {createTypeList}
    </div>
  )
}

List.defaultProps = {
  listItem: [],
  typeList: '',
  className: '',
  title: ''
}

export { List };