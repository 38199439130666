import fatherHood from '../assets/images/undraw_fatherhood_-7-i19 1.png';
import mathematics from '../assets/images/undraw_mathematics_-4-otb 1.png';
import technology from '../assets/images/undraw_visionary_technology_re_jfp7 1.png';
import navigator from '../assets/images/undraw_image_focus_re_qqxc 1.png';

const cards = [

  {
    image: fatherHood,
    caption: 'ALIN is Assistive',
    paragraph: [
      `Assistive technology helps every child learn their best, and with ALIN’s intuitive design, your child will learn in the way that’s 
        most suitable and comfortable for him or her.`,
      `There’s so much students need to grasp their own learning path, and it can make or break your child’s progress.`
    ],
    text: {
      name: 'Learn more...',
      patch: 'assistive'
    }
  },
  {
    image: mathematics,
    caption: 'ALIN is Learning',
    paragraph: [
      ` Most people struggle to understand math’s objectives when they’re tasked with memorizing, but with ALIN there’s always clear
         objectives and outcomes.`,
      `When it comes to improving your child’s attention span, ALIN helps with attention training.  This makes the math skills much more 
        accessible through concrete repetitive functions.`
    ],
    text: {
      name: 'Learn more...',
      patch: 'learning'
    }
  },


]

const cards2 = [
  {
    image: technology,
    caption: 'ALIN is Intelligence',
    paragraph: [
      `Instead of forcing your child to look at only their mistakes in a prescribed, sequenced way, ALIN intelligence provides choices.`,
      `ALIN’s intelligence program is like having a one-on-one tutor by your child’s side, providing support in real time and giving your 
        child the most cutting-edge math education.`
    ],
    text: {
      name: 'Learn more...',
      patch: 'intelligence'
    }
  },
  {
    image: navigator,
    caption: 'ALIN is Navigator',
    paragraph: [
      `The navigator sees when your child’s getting frustrated or losing attention and helps him or her through attention training.`,
      `The brain is a muscle that needs regular, conscious development to prevent a wandering mind.  Attention training helps your child
        understand when their mind wanders and become more aware on how to get their focus back to where it needs to be.`
    ],
    text: {
      name: 'Learn more...',
      patch: 'navigator'
    }
  },
]

const assistiveTexto =

{
  image: fatherHood,
  caption: 'ALIN is Assistive',
  paragraph: [
    `Assistive technology helps every child learn their best, and with ALIN’s intuitive design, your child will learn in the way that’s 
        most suitable and comfortable for him or her.`,
    `There’s so much students need to grasp their own learning path, and it can make or break your child’s progress.`,
    `Those needs are often invisible to the untrained eye, but with ALIN’s assistive technology, it catches those subtle nuances that makes your child unique and matches their preferred style of learning.`
  ]
}

const learningTexto =
{
  image: mathematics,
  caption: 'ALIN is Learning',
  paragraph: []
}

const intelligenceTexto =
{
  image: technology,
  caption: 'ALIN is Intelligence',
  paragraph: []
}
const navigatorTexto =
{
  image: navigator,
  caption: 'ALIN is Navigator',
  paragraph: []
}


export { cards, cards2, learningTexto, assistiveTexto, intelligenceTexto,navigatorTexto };