import styles from "./styles.module.css";

function index() {
  return (
    <main>
      <div className={styles.container}>
        <h1 className={styles.title}>
          Meet Samuel Davy
        </h1>
        <section className={styles.section}>
          <p>
            With a career spanning nearly two decades and three continents, Samuel Davy's passion for education is as boundless as his experience is diverse. Samuel's journey began on the outskirts of London, where he developed a strong work ethic from a young age. From part-time jobs at 12 to self-funding his education across two countries, his dedication to lifelong learning is evident.
          </p>
          <br />
          <p>
            After an early stint in banking, Samuel transitioned to the trades, becoming a skilled plumber and gas engineer. His work in the construction industry not only honed his technical skills but also instilled values of teamwork and professional trust. However, it was a chance encounter during his travels in Brazil that truly set him on his destined path.
          </p>
          <br />
          <p>
            Unable to continue in construction in São Paulo, Samuel discovered a new calling in teaching. His empathetic nature and keen observation skills quickly revealed the significant impact of children's emotional states on their learning. This realization, coupled with insights gained from meditation practices introduced by a psychologist friend, led Samuel to <b>pioneer</b> child-friendly mindfulness techniques in the classroom.
          </p>
          <br />
          <p>
            Specializing in Wellbeing in Primary Education for the past nine years, Samuel has empowered countless young learners to recognize and manage their emotions, fostering both personal growth and academic success. His holistic approach ensures that students leave his care equipped with a 'conscious toolkit' for life.
          </p>
          <br />
          <p>
            Now, as a driving force behind ALIN, Samuel is dedicated to extending his innovative educational methods to children worldwide. Partnering with Kelvin, he aims to integrate AI and holistic education, creating resilient learners ready to thrive in an ever-evolving world. Together, they champion a future where every child has the tools and mindset to succeed.
          </p>
          <br />
        </section>      
      </div>
    </main>
  )
}

export default index;
