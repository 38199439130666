import styles from "./styles.module.css";
import { Heading } from "../../components/Heading";
import { dataAlin } from '../../data/Alin';
import { List } from '../../components/List';

function HowAlinWorksPage() {
  return (
    <main>
      <div className={styles.container}>
        <Heading
          title={dataAlin.title}
          typeHeading='h1'
        />
        <section className={styles.section}>
          <img src={dataAlin.images} alt='Images of a girl listening'/>
          <List 
            listItem={dataAlin.list}
            typeList='ul'
            className={styles.listWithout}
          />
          <List 
            listItem={dataAlin.list1}
            typeList='ul'
            className={styles.listWith}
          />
          <ul>
            <li dangerouslySetInnerHTML={{ __html: dataAlin.list2[0] }}>
            </li>
          </ul>
        </section>

      </div>

    </main>
  );
}

export default HowAlinWorksPage;
