import imageHeader from '../assets/images/girl.png';



const dataHeader = {
  urlImage : imageHeader,
  title : 'Build whole child fluency in math literacy for improved attention and performance',
  text: 'Comprehensive, online math program that’s engaging, targeted, and free.',
  button: [
    {'name':'Students','to':'https://app.alin.ai/register?type=Student',
    'actions':false},
    {'name':'Parents','to':'https://app.alin.ai/register?type=Parent','actions':false},
    {'name':'Patrons','to':'https://app.alin.ai/register?type=Patron',
    'actions':false}]
}

export { dataHeader }