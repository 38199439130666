import styles from "./styles.module.css";
import { Heading } from "../../components/Heading";
import { assistiveTexto } from "../../data/cards";
import { Image } from "../../components/Image";
import { List } from "../../components/List";
function AssistivePage() {
    return (
        <main>
            <div className={styles.container}>
                <Heading
                    typeHeading='h1'
                    title={assistiveTexto.caption}
                />
                <section className={styles.section}> 
                        <div className={styles.sectionImage}>
                            <Image
                                imageUrl={assistiveTexto.image}
                                alt={`image ${assistiveTexto.caption}`}

                            /></div>
                        <List
                            typeList='ul'
                            listItem={assistiveTexto.paragraph}
                        /> 

                </section>
            </div>

        </main>
    );
}

export default AssistivePage;
