import styles from "./styles.module.css";
import { Heading } from "../../components/Heading";
import { learningTexto } from "../../data/cards";
import { Image } from "../../components/Image";
function LearningPage() {
    return (
        <main>
            <div className={styles.container}>
                <Heading
                    typeHeading='h1'
                    title={learningTexto.caption}
                />
                <section className={styles.section}>

                   
                        <div className={styles.sectionImage}>
                            <Image
                                imageUrl={learningTexto.image}
                                alt={`image ${learningTexto.caption}`}

                            /></div>
                       <p>What is your child actually learning?</p>

                       <p>Most children learn strategies, like memorization.  Strategies help them bypass learning requirements..
                        But is that what’s best for your child?</p>

                        <p>Learning places a number of demands on a student, like…</p>
                        <div className={styles.sectionList}>
                        <ul>
                            <li>Focus</li>
                            <li>Attention</li>
                            <li>Dedication</li>
                            <li>Training</li>
                        </ul> 
                        </div>

                        <p>These demands enable your child to get better at math.  Most people struggle to understand math’s objectives when they’re tasked with memorizing, but with ALIN there’s always clear objectives and outcomes.
                        </p>
                        <p>When it comes to improving your child’s attention span, ALIN helps with attention training.  This makes the math skills much more accessible through concrete repetitive functions.</p>  

                        <p>Attention training instills the learning practice required to make math gains.</p>

                 

                </section>
            </div>

        </main>
    );
}

export default LearningPage;
