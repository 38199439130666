
interface PropsImage {
  imageUrl: string
  alt: string
  className?:string
  handleClick?:() => void
  caption?: string
}
function Image({imageUrl,alt,className,handleClick,caption}:PropsImage) {
  return (
    <figure className={className} onClick={handleClick}>
        <img src= {imageUrl} alt={alt}>

        </img>
        {caption? <figcaption>{caption}</figcaption>:null}
    </figure>
    
    
  );
}
Image.defaultProps ={
  imageUrl : "",
  alt: "",
  className: ""
}

export { Image };