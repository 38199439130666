import girlListeing from '../assets/images/Rectangle 563.png';
const list = [
  `ALIN personalizes each and every user’s journey through a student-
centric, fluent, whole-body approach.`,

`When you’re learning anything new, it’s exciting but also scary.
 Especially when we hit a roadblock.`,

`When the challenging thinking happens, our attention becomes limited
or even fixated.  Once derailed from our full attention, it’s harder to
get back on track.`,

`It decreases our ability to think clearly about the situation, and we often devalue ourselves in the process.`,

`With ALIN, students learn critical mathematical skills while also developing their attention spans and self-awareness through a focused process.`,

`When students feel self-confident, they tackle problems, big or small, with motivation and grit. `,

`But, when students feel like they <b>can’t do something</b>, they’re <b>always right.</b>`,

`It’s because this frustration level hinders their ability to unlock a new perspective in their obstacles.`,

`But when students couple math skills with a meditative approach, they’re better equipped to solve their problems effectively and accurately.`,

`The attention training is just as adaptive as the learning pathway to encourage your child to recenter when they need it most.`,

`Attention training in education has many benefits that feed into other areas of your child’s life.  Attention training helps your child…`,
]

const list1 =[

  `Manage stressful situations`,
  `Generate imagination and creativity`,
  `Increase self-awareness and mindfulness`,
  `Boost problem solving abilities`,
  `Expand capacity for patience and comprehension`,
  `Maximize the learning pathway`,
  `Embrace problems with confidence and creativity`,
]

const list2 =['When students are equipped with the right tools to break through their barriers, they finally get to see the potential <b>you always knew</b> was there.']

const images = girlListeing;

const title = 'How ALIN works'

const dataAlin = {
  list,
  list1,
  list2,
  images,
  title
}

export { dataAlin };
