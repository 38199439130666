import { Heading } from "../Heading"
import { cards, cards2 } from '../../data/cards'
import { Card } from "../Card"
import styles from './styles.module.css'

function Cards () {
  return(
    <section>
      <div className={styles.container}>
        <Heading 
          title='ALIN is..'
          typeHeading='h2'
        />
        <ul>
          {cards.map((card,index)=>(
            
            <Card 
            key={`card ${index}`}
            image={card.image}
            caption={card.caption}
            paragraph={card.paragraph}
            name={card.text.name}
            patch={card.text.patch}
            className={styles.card}
            />
          ))}
        </ul>
        <ul>
        {cards2.map((card,index)=>(
            
            <Card 
            key={`card ${index}`}
            image={card.image}
            caption={card.caption}
            paragraph={card.paragraph}
            name={card?.text.name}
            patch={card.text.patch}
            className={styles.card}
            />
          ))}
        </ul>

      </div>
    </section>
  )
}

export { Cards };