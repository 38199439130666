// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `article{
  background: linear-gradient(90deg, var(--color-primary) 0%, var(--color-secundary) 100%);

}

.styles_container__KaDUs{
  width: var(--container-width-md);
  max-width: 1920px;
  margin-inline: auto ;
  margin-top: 181px;
  max-width: 1512px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.styles_container__KaDUs > ul{
  list-style: none;
  margin: 0 122px;
  font-style: normal;
  font-weight: 420;
  font-size: 30px;
  line-height: 43px;
  width: 600px;
  
}

.styles_container__KaDUs > ul > li{
  text-align: center;

}

@media screen and (max-width: 820px ){

  .styles_container__KaDUs > ul{
    list-style: none;
    margin: 0 clamp(21px,10vw,122px) 0 clamp(21px,10vw,122px);
    font-size: clamp(24px,90vw,30px);

  }
  
  .styles_container__KaDUs > ul > li{
    font-size: clamp(24px,90vw,30px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Slider/styles.module.css"],"names":[],"mappings":"AAAA;EACE,wFAAwF;;AAE1F;;AAEA;EACE,gCAAgC;EAChC,iBAAiB;EACjB,oBAAoB;EACpB,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;AAEzB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,YAAY;;AAEd;;AAEA;EACE,kBAAkB;;AAEpB;;AAEA;;EAEE;IACE,gBAAgB;IAChB,yDAAyD;IACzD,gCAAgC;;EAElC;;EAEA;IACE,gCAAgC;EAClC;AACF","sourcesContent":["article{\n  background: linear-gradient(90deg, var(--color-primary) 0%, var(--color-secundary) 100%);\n\n}\n\n.container{\n  width: var(--container-width-md);\n  max-width: 1920px;\n  margin-inline: auto ;\n  margin-top: 181px;\n  max-width: 1512px;\n  height: 80px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  \n}\n.container > ul{\n  list-style: none;\n  margin: 0 122px;\n  font-style: normal;\n  font-weight: 420;\n  font-size: 30px;\n  line-height: 43px;\n  width: 600px;\n  \n}\n\n.container > ul > li{\n  text-align: center;\n\n}\n\n@media screen and (max-width: 820px ){\n\n  .container > ul{\n    list-style: none;\n    margin: 0 clamp(21px,10vw,122px) 0 clamp(21px,10vw,122px);\n    font-size: clamp(24px,90vw,30px);\n\n  }\n  \n  .container > ul > li{\n    font-size: clamp(24px,90vw,30px);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__KaDUs`
};
export default ___CSS_LOADER_EXPORT___;
