// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
main{

}

.styles_container__cMzLB{
    width: var(--container-width-md);
    margin: 80px auto 82px auto ;
    max-width: 1920px;
}

.styles_container__cMzLB > div > h1 {
    font-style: normal;
    font-weight: 420;
    font-size: 40px;
    line-height: 57px;
    text-align: center;
}

.styles_list__EHx\\+7{
    /* width: 1013px; */
    margin-top: 64px auto auto 0;
    display: flex;
    justify-content: space-between;
}

.styles_list__EHx\\+7 > div > ul{
    list-style: none;
}

.styles_list__EHx\\+7 > div > ul > li {
    font-style: normal;
    font-weight: 420;
    font-size: 22px;
    line-height: 31px;
    margin-bottom: 10px;
}

.styles_section__iM5vS {
    width: 896px;
    margin: 70px auto 0 auto;
}

.styles_section__iM5vS > article {
    background: var(--primary-color-white);
    margin-bottom: 30px;
}

.styles_section__iM5vS > article > h4{
    margin-bottom: 5px;
    border-bottom: 1px solid var(--color-primary-black);
}

.styles_section__iM5vS > article > p{
    margin: 20px 0px;
    
}

@media screen and (max-width: 1280px ){

    .styles_list__EHx\\+7{
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .styles_section__iM5vS{
        width: clamp(737px,89vw,896px);
    }

}

@media screen and (max-width: 820px ){
    .styles_section__iM5vS{
        width: clamp(336px,89vw,737px);
    }
 
}`, "",{"version":3,"sources":["webpack://./src/pages/FaqPage/styles.module.css"],"names":[],"mappings":";AACA;;AAEA;;AAEA;IACI,gCAAgC;IAChC,4BAA4B;IAC5B,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,4BAA4B;IAC5B,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,sCAAsC;IACtC,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,mDAAmD;AACvD;;AAEA;IACI,gBAAgB;;AAEpB;;AAEA;;IAEI;QACI,eAAe;QACf,aAAa;IACjB;;IAEA;QACI,8BAA8B;IAClC;;AAEJ;;AAEA;IACI;QACI,8BAA8B;IAClC;;AAEJ","sourcesContent":["\nmain{\n\n}\n\n.container{\n    width: var(--container-width-md);\n    margin: 80px auto 82px auto ;\n    max-width: 1920px;\n}\n\n.container > div > h1 {\n    font-style: normal;\n    font-weight: 420;\n    font-size: 40px;\n    line-height: 57px;\n    text-align: center;\n}\n\n.list{\n    /* width: 1013px; */\n    margin-top: 64px auto auto 0;\n    display: flex;\n    justify-content: space-between;\n}\n\n.list > div > ul{\n    list-style: none;\n}\n\n.list > div > ul > li {\n    font-style: normal;\n    font-weight: 420;\n    font-size: 22px;\n    line-height: 31px;\n    margin-bottom: 10px;\n}\n\n.section {\n    width: 896px;\n    margin: 70px auto 0 auto;\n}\n\n.section > article {\n    background: var(--primary-color-white);\n    margin-bottom: 30px;\n}\n\n.section > article > h4{\n    margin-bottom: 5px;\n    border-bottom: 1px solid var(--color-primary-black);\n}\n\n.section > article > p{\n    margin: 20px 0px;\n    \n}\n\n@media screen and (max-width: 1280px ){\n\n    .list{\n        flex-wrap: wrap;\n        row-gap: 10px;\n    }\n\n    .section{\n        width: clamp(737px,89vw,896px);\n    }\n\n}\n\n@media screen and (max-width: 820px ){\n    .section{\n        width: clamp(336px,89vw,737px);\n    }\n \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__cMzLB`,
	"list": `styles_list__EHx+7`,
	"section": `styles_section__iM5vS`
};
export default ___CSS_LOADER_EXPORT___;
