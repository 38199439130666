import React, { useState } from "react";
import styles from "./styles.module.css";
import { Image } from "../Image";
import arrowLeft from "../../assets/images/logos/arrow_left.svg";
import arrowRight from "../../assets/images/logos/arrow_right.svg";

interface SliderState {
  indexSlider: number;
}
const data = ['“I’m just not good at math.”','“This is boring.”','“I hate math.”','“It’s too hard.”' ];
function Slider() {
  const [indexSlider, setIndexSlider] = useState<SliderState["indexSlider"]>(0);
  const [toggle, setToggle] = useState(false);
  const quantity = data.length;
  const index = indexSlider;

  const next = (index: number) => {
    setIndexSlider(index >= quantity - 1 ? 0 : index + 1);
  };
  const previous = (index: number) => {
    setIndexSlider(index <= 0 ? 3 : index - 1);
  };

  setTimeout(() => {
    const index = indexSlider;
    next(index);
  }, 3000);

  return (
    <article>
      <div className={styles.container}>
        <Image
          imageUrl={arrowLeft}
          alt="icon of arrow right"
          handleClick={() => {
            previous(index);
          }}
        />
        <ul>{<li>{data[index]}</li>}</ul>
        <Image
          imageUrl={arrowRight}
          alt="icon of arrow right"
          handleClick={async () => {
            next(index);
            setToggle(true);
            console.log(toggle, "clicked");
          }}
        />
      </div>
    </article>
  );
}

export { Slider };
