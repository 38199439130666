

interface PropsButton {
  name: string
  className: string
  handleClick?: ()=> void
  to:  any,
  actions:Boolean
}
function Button ({name,className,handleClick,to,actions}:PropsButton) {
  const targetAction = actions ? "_blank" : "";
  return(
    <button className={className} onClick={handleClick}>
      <a href={to} target={targetAction}>
        {name}
      </a>
    </button>
  )
}
Button.defaultProps = {
  name: 'button',
  className: 'Button',
  
}

export { Button }