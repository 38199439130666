import { useState } from "react";
import styles from "./styles.module.css";
import { Link, NavLink } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { menu } from "../../data/menuPrimary";
import Logo from "../../assets/images/logos/all-logo.png";
import { Button } from "../Button";

function Navbar() {
  const [isNavShowing, setIsNavShowing] = useState(false);
  return (
    <nav>
      <div className={styles.container}>
        <div className={styles.navbarContainer}>
          <div className={styles.navLeft}>
            <Link
              to="/"
              className={styles.Logo}
              /* onClick={setIsNavShowing(false)} */
            >
              <img src={Logo} height={32} alt="Logo Alin With Kelvin" />
            </Link>
          </div>
          <div
            className={`${styles.navRight} ${
              isNavShowing ? `${styles.navbarShow}` : `${styles.navbarHide}`
            }`}
          >
            <ul className={styles.navbarLinks}>
              {menu.map((item) => (
                <li key={item.to}>
                  <NavLink
                    to={item.to}
                    onClick={() => setIsNavShowing((prev) => !prev)}
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
           
            <Button 
              className={styles.authBtnLogin} 
              name="Log in" 
              to='https://app.alin.ai/'
              actions={true}
            />
            {/* <Button 
              className={styles.authBtnGetStarted} 
              name="Get started" 
              to='https://app.alin.ai/register'
              actions={true}
            /> */}
          </div>
          <button
            className={styles.navbarToggleBtn}
            onClick={() => setIsNavShowing((prev) => !prev)}
          >
            {isNavShowing ? <AiOutlineClose /> : <AiOutlineMenu />}
          </button>
        </div>
      </div>
    </nav>
  );
}

export { Navbar };
