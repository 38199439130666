import styles from "./styles.module.css";
import { Heading } from "../../components/Heading";
import { navigatorTexto } from "../../data/cards";
import { Image } from "../../components/Image";
function NavigatorPage() {
    return (
        <main>
            <div className={styles.container}>
                <Heading
                    typeHeading='h1'
                    title={navigatorTexto.caption}
                />
                <section className={styles.section}>


                    <div className={styles.sectionImage}>
                        <Image
                            imageUrl={navigatorTexto.image}
                            alt={`image ${navigatorTexto.caption}`}

                        /></div>
                    <p>

                        Our navigator helps your child develop the crucial skills needed to propel their mathematical learning.</p>

                    <p> The navigator sees when your child’s getting frustrated or losing attention and helps him or her through attention training.</p>

                    <p> The brain is a muscle that needs regular, conscious development to prevent a wandering mind.  Attention training helps your child understand when their mind wanders and become more aware on how to get their focus back to where it needs to be.</p>

                    <p> Attention training instills learning practice.  This will help your child</p>




                <div className={styles.sectionList}>
                    <ul>
                        <li>Maintain concentration on set tasks</li>
                        <li>Filter unnecessary stimuli to meet goals</li>
                        <li>Improve executive functions</li>
                        <li>Strengthen working memory</li>
                    </ul>
                </div>


            </section>
        </div>

        </main >
    );
}

export default NavigatorPage;
