import React, { useState } from 'react';
import { Heading } from "../../components/Heading";
import email from '../../assets/images/logos/arcticons_yahoo-mail.svg';
import styles from "./styles.module.css"; 
import emailjs from 'emailjs-com';
function ContactPage() {
  
  const frmContact = { firstName:'', secondName:'', email:'', message:'' };
  const [contact,setContact] = useState(frmContact);
  const [inputs, setInputs] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [errorComponent, setErrorComponent] = useState("");

  const resetErrors = () => {
    setErrorComponent("");
    setErrorMessage("");
  };


  const isValid = () => {
  
    return true;
  };

  
  const handleChange = (e:any) => { 
		const {name,value} = e.target;
		setContact({...contact,[name]:value}); 
   };

   const handleSubmit = (e:any) =>{
	    e.preventDefault();
	   
		emailjs.send('default_service','template_qc0q6dw', contact, 'WOi_cGaZdf__JFBMi')
		.then((response) => {
      console.log('SUCCESS!', response.status, response.text);
				   setContact(frmContact);
		}, (err) => {
				   console.log('FAILED...', err);
		});
   }
  return (
    <main>
      <div className={styles.container}>
        <aside className={styles.aside}>
          <section className={styles.section}>
            <span>
              If you have any questions, we can help you!
            </span>
            <Heading 
              title='Contact us'
              typeHeading='h2'

            />
            <p>
              If you need help with any topic or want to contact us to request information about our trainings...
            </p>
            <p>
              Our doors are open!
            </p>
            <div>
              <img src={email} alt='Icon of the email'/>
              <span>hello@alin.ai</span>
            </div>
          </section>
        </aside>
        <div >
          <div></div>
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <fieldset>
            <label htmlFor="firstName" >
              First Name
            </label>
            <input type='text' id='firstName' name='firstName' value={contact.firstName}  onChange={handleChange} placeholder='First Name' />
          </fieldset>
          <fieldset>
            <label htmlFor="secondName" >
              Second Name
            </label>
            <input type='text' id='secondName' name='secondName' value={contact.secondName}  onChange={handleChange} placeholder='Second Name'  />
          </fieldset>
          <fieldset>
            <label htmlFor="email" >
              Email address
            </label>
            <input type='email' id='email' name='email' value={contact.email} onChange={handleChange}  placeholder='Email address' />
          </fieldset>
          <fieldset>
            <label htmlFor="message" >
              How can we help?
            </label>
            <textarea id='message' name='message' value={contact.message}  onChange={handleChange} placeholder='Message'  />
          </fieldset>
          
          {errorComponent !== "" && (
                <p className="help is-danger is-justify-content-center has-text-centered">
                  {errorMessage}
                </p>
              )}

          <button type="submit">Submit</button>
        </form>
      
      </div>
    </main>
  )
}

export default ContactPage;
