// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__jd93S {
    text-align: center;
    margin: 50px 0;
}

.styles_section__QuNqo {
    width: 896px;
    margin: 40px auto 0 auto;
}

.styles_section__QuNqo > article {
    background: var(--primary-color-white);
    margin-bottom: 30px;
}

.styles_section__QuNqo > article > h4{
    margin-bottom: 5px;
    border-bottom: 1px solid var(--color-primary-black);
}

.styles_section__QuNqo > article > p{
    margin: 20px 0px;
    
}

@media screen and (max-width: 1280px ){

    .styles_list__xzhMl{
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .styles_section__QuNqo{
        width: clamp(737px,89vw,896px);
    }

}

@media screen and (max-width: 820px ){
    .styles_section__QuNqo{
        width: clamp(336px,89vw,737px);
    }
 
}

@media screen and (max-width: 600px ){
    .styles_title__jd93S{
        font-size: 2.5rem;
    }
 
}`, "",{"version":3,"sources":["webpack://./src/pages/MeetCoFounder/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,sCAAsC;IACtC,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,mDAAmD;AACvD;;AAEA;IACI,gBAAgB;;AAEpB;;AAEA;;IAEI;QACI,eAAe;QACf,aAAa;IACjB;;IAEA;QACI,8BAA8B;IAClC;;AAEJ;;AAEA;IACI;QACI,8BAA8B;IAClC;;AAEJ;;AAEA;IACI;QACI,iBAAiB;IACrB;;AAEJ","sourcesContent":[".title {\n    text-align: center;\n    margin: 50px 0;\n}\n\n.section {\n    width: 896px;\n    margin: 40px auto 0 auto;\n}\n\n.section > article {\n    background: var(--primary-color-white);\n    margin-bottom: 30px;\n}\n\n.section > article > h4{\n    margin-bottom: 5px;\n    border-bottom: 1px solid var(--color-primary-black);\n}\n\n.section > article > p{\n    margin: 20px 0px;\n    \n}\n\n@media screen and (max-width: 1280px ){\n\n    .list{\n        flex-wrap: wrap;\n        row-gap: 10px;\n    }\n\n    .section{\n        width: clamp(737px,89vw,896px);\n    }\n\n}\n\n@media screen and (max-width: 820px ){\n    .section{\n        width: clamp(336px,89vw,737px);\n    }\n \n}\n\n@media screen and (max-width: 600px ){\n    .title{\n        font-size: 2.5rem;\n    }\n \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__jd93S`,
	"section": `styles_section__QuNqo`,
	"list": `styles_list__xzhMl`
};
export default ___CSS_LOADER_EXPORT___;
