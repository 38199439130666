import React, { useState } from "react";
import { Heading } from "../../components/Heading";
import { Link } from "react-router-dom";
import styles from './styles.module.css';
import { DATA_USER } from "../../data/dataUser";
import { useNavigate } from "react-router-dom";
import { useDispatch} from "react-redux";
import { actions } from "../../actions";
import { LoginState } from "../../reducers/data";
import { useAppSelector} from "../../reducers/hooks"

interface AuthPageState {
  isLogged: boolean
  inputValues: LoginState
  
}

const AuthPage = () => {
  
  const dispatch = useDispatch();
  const loginUser= useAppSelector(state => state.login)
  const [isLogged,setIsLogged]=useState<AuthPageState['isLogged']>(false)
  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    const content =event.target;
    dispatch(actions.loginOnChange(content))
  }
  console.log(loginUser)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if((loginUser.userName===DATA_USER.userName || loginUser.userName===DATA_USER.email) && DATA_USER.password && !isLogged){
      alert(`Hello user ${loginUser.userName} just logged in.`)
      setIsLogged(true)
      navigate("/")
    }else{
      alert(`Please check that your email or userName and password are correct.`)
    }
  }
  return (
    <form onSubmit={handleSubmit}>
    <div className={styles.container}>
      <Heading 
        title='Hello again, good to see you!'
        typeHeading='h4'
      />
      <Heading 
        title='Login'
        typeHeading= 'h3'
      />
      <fieldset>
        <label htmlFor="userName" >
          Email or userName
        </label>
        <input type='text' id='userName' name='userName' onChange={handleChange} value={loginUser.userName}/>
      </fieldset>
      <fieldset>
        <label htmlFor="password" >
          Password
        </label>
        <input type='password' id='password' name='password' onChange={handleChange} value={loginUser.password}/>
      </fieldset>
      <button type="submit">Login</button>
      <div className={styles.remember}>
        <input type='checkbox' id='remember' name='remember' onChange={handleChange} value={loginUser.remember}/>
        <label htmlFor="remember" >
          Remember
        </label>
      </div>
      <Link 
        to='/'
        className={styles.forgot}
      >
        Forgot password?
      </Link>
      <Link 
        to='/getstarted'
        className={styles.account}
      >
        Create an account
      </Link>
      <Link
        to='/'
        className={styles.terms}
      >
        By signing up Alin, you agree to our <strong>Terms</strong> and <strong>Privacy Policy</strong>.
      </Link>

    </div>
  </form>
  )
}

export default AuthPage;
