import styles from "./styles.module.css";
import { Heading } from '../../components/Heading';
import { List } from '../../components/List';
import { AlinPlatform} from '../../data/Alin Plataform';
import image from '../../assets/images/undraw_image_focus_re_qqxc_1.png';
import { Link } from 'react-router-dom'

function ThePlatformPage() {
  return (
    <main>
      <div className={styles.container}>
        <Heading 
          typeHeading='h1'
          title={AlinPlatform.title}
      />
      <section className={styles.section}>
        <List 
          listItem={AlinPlatform.list1}
          typeList='ul'
        />
        <div className={styles.sectionImage}>
          <label htmlFor="fileImage">
          <img src={image} alt={AlinPlatform.nameInsert}/>
          </label> 
        </div>
        <List 
          listItem={AlinPlatform.list2}
          typeList='ul'
        />
        <ul>
          <li>{AlinPlatform.list3}
          
        <Link to={'/contact'}> 
        Contact us
        </Link> </li>
        </ul>
      </section>

      </div>
    </main>

  );
}

export default ThePlatformPage;