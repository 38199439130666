import imageHeader from '../assets/images/undraw_process_re_gws7 1.png';

const child = {
  texts: [
    {
      content: 'Your child’s struggling with math concepts, especially word problems. Every time she sits down to get started on math problems, she’s confused. Or worse, she has trouble even concentrating long enough to get started.'
    },
    {
      content: 'She’s reading the problem with success, but she’s unsure what the invisible expectation is on how to solve it and what operation to use.'
    },
    {
      content: 'She shuts down and nothing gets accomplished'
    },
    {
      content: 'Being a parent, you want resources to be a good fit for your child. You want to know you found a way to help your child not only succeed but feel successful and confident, too.'
    },

  ],
  image: imageHeader,
  list: {
    title: 'You want your child to…',
    item: [
      'Build independence and autonomy',
      'Advance their mathematical capacity',
      'Embrace challenges with innovation and enthusiasm',
      'Create personalized approaches to problem solving',
      'Recognize frustrations as a starting point for reflection',
      'Acquire the necessary skills to be self-confident and independent',
      'Direct and discern attention spans to quality of information'
    ],
    textEnd: 'With ALIN, your child can be successful and solve problems through a disciplined yet creative approach.'
  }
}

export { child }