 
function NotFoundPage() {
  return (
       <>
       <div  className="numberBody">
       <div className="number">404</div>
       <div className="text"><span>Ooops...</span>
       <br/>
       Page not found</div></div>
       </>
       );
       }
export default NotFoundPage;
