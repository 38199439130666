
import styles from "./styles.module.css";
// import './style.css'
function AboutUsPage() {
  return (
    <main className='AboutUsPage'>
      <div className={styles.container}>
        <h2 className={styles.title}>
          Our Philosophy: How To Leverage Technology to Empower the Next Generation
        </h2>
        <section className={styles.section}>
          <h3 className={styles.subtitle}>How Can Technology Bring Us Closer to Ourselves?</h3>

          <p>
            We are well aware of the impacts of COVID-19 on adults. Headline after headline
            throughout the pandemic focused on notions like
          </p>

          <ul className={styles.list}>
            <li>
              work from home
            </li>
            <li>
              vaccine and mask debates
            </li>
            <li>
              social distancing
            </li>
          </ul>

          <h3 className={styles.subtitle}>
            But what about our children?
          </h3>

          <p>
            With schools closed and social distancing in full swing for over a year, the typical growth-curve
            of a child or adolescent’s development was significantly halted. With this “indefinite pause”
            <b> youth fell back an average of five months,</b> accompanied by a host of challenges such as
            anxiety, isolation, and an over-reliance on mind-numbing, toxic social networks.
          </p>

          <p>
            Unfortunately, COVID and the corresponding loss of education resulted in a new challenge
            affecting youth’s minds, goals, and attention spans:
          </p>

          <ul className={styles.list}>
            <li>
              tech addiction
            </li>
            <li>
              “scroll holes”
            </li>
            <li>
              information overload
            </li>
          </ul>

          <p>
            The most severely impacted were children, who have grown up in a technology-infused world.
          </p>

          <p>
            The resulting retreat into technology-infused isolation intensified the attention deficit amidst a
            wealth of information, and as Nobel Laureate Herbert A. Simon suggested,
          </p>

          <div className={styles.subtitle}>
            <b>
              “a wealth of information creates a poverty of attention.”
            </b>
          </div>

          <p>
            Attention is not an infinite resource. This phenomenon made us ask – in the seemingly
            endless expanse of information – what is the next generation of children paying
            attention to?
          </p>
          <br />

          <p>
            2020, the year of lost education for the world’s children, served as the spark to create
            change for ALIN’s founder, Kelvin Lwin. Born in Myanmar (Burma), Kelvin experienced his
            own year of lost education in 1989, when his family fled their home after the attempted 
            uprising against the government. Political instability, loss of education and routine, and future
            uncertainty in his own world affected Kelvin profoundly. He was reminded of this impact in 2020,
            when COVID left its permanent mark on our world.
          </p>

          <h3 className={styles.subtitle} style={{fontSize: '32px', margin: '40px auto'}}>Introducing Assistive Intelligence (AI)</h3>
          <p>
            If COVID taught us one thing, it’s that life as we know it is fragile. Our relationship with
            ourselves– regardless of age – is crucial to thrive in modern society. As a modern thinker who
            believes in leveraging technology to solve current and future problems, Kelvin asked a simple
            but poignant question:
          </p>

          <div className={styles.subtitle}>
            <b>
              How can we use modern technology to inform and empower the next general of
              Human Intelligence?
            </b>
          </div>

          <div className={styles.subtitle}>
            <b>
              The answer: a refocusing, a reformatting – in the form of Assistive Intelligence.
            </b>
          </div>

          <p>
            Assistive Intelligence uses technology to bring out the best in a generation of thinkers;
            helping them to not only “catch up” after COVID, but to level up in a chaotic, divided world.
          </p>

          <p>
            Our platform uses Assistive Intelligence to bring out the best in human potential, starting
            with our youth who have been neglected for multiple years.
          </p>

          <p>
            It begins with using the best that technology has to offer to “speak the language” of digital
            natives. It also involves facilitating meditation-enhanced teaching methods and teaching critical
            skills that people don’t learn in school such as
          </p>

          <ul className={styles.list}>
            <li>
              mental fortitude
            </li>
            <li>
              antifragility
            </li>
            <li>
              attention
            </li>
          </ul>

          <p>
            At ALIN, we are laser focused on bringing out the best in an entire generation of
            learners. We believe in bridging the gap between modern status and unlocking the
            limitless power of human potential.
          </p>
        </section>
      </div>
    </main>

  );
}

export default AboutUsPage;
