import React from "react";
import { Image } from '../Image';
import { Heading } from "../Heading";
import { dataHeader } from '../../data/HeroBanner';
import styles from './styles.module.css';
import { Button } from "../Button";

function HeroBanner() {
  return (
  <header >
    <div className={styles.container}>
      <Image
        imageUrl={dataHeader.urlImage}
        className={styles.image}
      />
      <div className={styles.information}>
        <Heading 
          title={dataHeader.title}
          typeHeading='h2'
          className={styles.title}
        />
        <p>{dataHeader.text}</p>
        <div className={styles.containerButton}>
          { dataHeader.button.map(button => (
            <Button 
              name={button.name}
              className={styles.button}
              key={button.name}
              to={button.to}
              actions={button.actions}
            />
          ))}
        </div>
      </div>

    </div>
  </header>);
}

export { HeroBanner };

