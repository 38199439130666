import { createStore, combineReducers } from "redux";
import { loginReducer } from "../reducers/loginReducer";
import { registerReducer } from "../reducers/registerReducer";
import { messageReducer} from "../reducers/messageReducer";

const reducers = combineReducers( {
    login:    loginReducer,
    register:    registerReducer,
    message:    messageReducer
})

const store = createStore(reducers);

export { store };

export type RootState = ReturnType<typeof store.getState>
                                         