import { Image } from "../Image";
import { List } from "../List";
import { Link } from "react-router-dom";

interface PropsCard {
  image: string
  caption: string
  paragraph: Array <string>
  name: string
  patch: string
  className: string
}
function Card ({image,caption,paragraph,name,patch,className}:PropsCard) {

  // const {name,patch}=product.text;
  return(
    <article className={className}>
      <Image 
        imageUrl={image}
        alt={`image ${caption}`}
        caption={caption}
      />
      <List 
        typeList='ul'
        listItem={paragraph}
      />
         <Link to={patch}>
        {name}
      </Link>
    </article>
  )
}

export { Card }