import { RegisterReducerActions, RegisterState } from "./data"

const INITIAL_STATE: RegisterState = {
    month: '',
    day: '',
    year: '',
    userName: '',
    email: '',
    password: ''
   }
   const registerReducer = (state=INITIAL_STATE,action:RegisterReducerActions) => {
       switch(action.type){
           case '@register/change_value':
             const {inputName, inputValue}= action.payload
             return{
               ...state,
               [inputName]:inputValue
             }
          case '@reset':
            return INITIAL_STATE;
          default:
            return state;
       }
   }
   
   export { registerReducer }