import { LoginReducerActions, RegisterReducerActions, MessageReducerActions } from "../reducers/data"

const loginOnChange = (content:EventTarget & HTMLInputElement):LoginReducerActions => {
    const { name,value}= content
    return {
        type: '@login/change_value',
        payload: {
            inputName: name,
            inputValue: value
        }
    }
}

const registerOnChange = (content:EventTarget & (HTMLInputElement | HTMLSelectElement)):RegisterReducerActions => {
    const { name,value}= content
    return {
        type: '@register/change_value',
        payload: {
            inputName: name,
            inputValue: value
        }
    }
}

const messageOnChange = (content:EventTarget & (HTMLInputElement | HTMLTextAreaElement)): MessageReducerActions => {
    const { name,value}= content
    return {
        type: '@message/change_value',
        payload: {
            inputName: name,
            inputValue: value
        }
    }
}

const reset = () => {
    return{
        type: '@reset'
    }
}

const actions = {
    loginOnChange,
    registerOnChange,
    messageOnChange,
    reset

}

export { actions }