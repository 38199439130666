export const menu = [
  
  {
    name: "The Platform",
    to: "/theplatform",
  },
  {
    name: "Attention Training",
    to: "/attentiontraining",
  },
  {
    name:"AI Research", to: "/ai-research",
  }
];
