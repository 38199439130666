
import { Heading } from '../../components/Heading';
import styles from "./styles.module.css";
function FounderPage() {
  const title = "The Meaning of ALIN";
  return (
    <main className='meditationPage'>
      <div className={styles.container}>
        <Heading
          typeHeading='h1'
          title={title}
        />
        <section className={styles.section}>
          <p>
            The name ALIN stands for many things. For one, it stands for Assistive Learning
            Intelligence Navigator. For another, it means “The Light” in Burmese, which is
            reminiscent of Kelvin’s family’s experience in journeying from a politically unstable
            situation to seek the light through the cracks.
          </p>
          <p>
            ALIN hopes to modernize and humanize traditional learning. At the core, we embrace the bold yet crucial ambition to infuse this light in a lost generation. A generation which, armed with the power of meditation-centered learning, will truly be unstoppable.
          </p>
          <p>
            The power of ALIN’s potential is founded on the belief that attention and curiosity is the
            key to unlocking our potential as individuals and as a society. That's why we're committed to making these practices accessible to our future world leaders. Our platform makes it easy for people of all ages and backgrounds to learn with attention at the forefront, with lessons tailored to their unique needs and interests in an inclusive learning space.

          </p>

          <p>
            This oversaturation leaves us feeling…
          </p>
        </section>
        <Heading
          typeHeading='h1'
          title="Meet the Founder"
        />
        <section className={styles.section}>
          <p>Our founder, Kelvin Lwin, sits at the pinnacle of where learning meets technology. He is a
            leader in conscious AI – AI with purpose.</p>

          <p>Center to the progression of human potential post-COVID is the importance of the “self”
            amidst a world and society that constantly attempts to pry the soul from the brain.</p>

          <p>Kelvin’s inspiration for ALIN stemmed from his own lost education and challenges and
            the similarities he witnessed in a post-COVID world. Having escaped his native Myanmar
            amid political instability, Kelvin’s family settled in the United States. </p>

          <p>It was in the US where Kelvin experienced the lifeline – or light – that education could bring a child.</p>

          <p>Embodying antifragility, Kelvin went on to become a Teaching Professor of Computer
            Science at the University of California. There he witnessed how proper education can act
            as a catalyst, a propulsive force that allows individuals to rise above their circumstance.</p>

          <p>Like Kelvin, many of his students, despite their backgrounds, were able to power through
            their education with a fierce determination. But, they needed more guidance.</p>

          <p>As Kelvin began teaching the power of AI and Deep Learning, he saw outer technology converging with the inner workings of the mind. During a 30-day meditation retreat, he experienced another form of ancient mental technology. Both the inner and outer, the
            ancient and the modern, hinge upon the power of observation and the ability to filter
            and choose the corresponding action.

          </p>

          <p>Through his professional and lived experience, he saw modern technology causing humans to become increasingly distant from themselves. Kelvin found a way forward to use combined technologies to <b><i>unlock, rather than hinder, human potential</i></b>.
          </p>
          <p>As a result, ALIN came to life – determined to help a generation cultivate vital learning skills while fostering an unshakeable relationship with the self. ALIN sits at the juncture of
          </p>
          <p>Kelvin’s cultural roots of modern mindfulness in Myanmar, coupled with the personalization and scalability of AI tools. ALIN hopes to help solidify the uniquely human qualities such as hard work, antifragility, mental fortitude, and the ability to control one’s attention on a greater goal.
          </p>
          <p>With ALIN, we hope to unleash individuated human potential in all of us.
          </p>
          <p>When Kelvin Lwin came to the United States from Burma at 12 years old, he was tasked by his preceptor monk to gain knowledge to bring back to Burma one day.
          </p>
          <p>Since then he’s meditated on what it means to be human and how to become a better version of himself, especially with service to others.  Through his meditation, he’s figured out how to combine his experiences as a teacher, engineer, and meditator through Alin.AI.
          </p>
          <p>Kelvin founded Alin.AI on the belief that the 21st century education system needs help.  Kelvin asks one simple question:
          </p>
          <p  className='text-center'>What kind of education do you want children to have?</p>

          <p> In the past, education’s touted that project based education is the best.  Before project based learning, rote memorization was the best.  These methods don’t work for <b>everyone</b>.
          </p>
          <p>What if there was an expert who understood each child’s preferences, background, and unique idiosyncrasies to provide the best mixed learning experience?
          </p>
          <p>We’ve done the one size fits all teaching methodologies for centuries, but it’s proven that it doesn’t work in the best way.
          </p>
          <p>AI personalizes and understands each child’s complexities.  From their quirks to preferences, it can tap into the best assessment tools and pedagogy for each child.
          </p>
          <p>When you keep watching something, you see patterns, which is how AI is equipped to make masterful predictions that’s best suited for each child.
          </p>
          <p  className='text-center'>That’s how Alin.AI emerged</p>


          <p>Using AI to give students a truly personalized, 1:1 learning experience, it’s Kelvin’s core mission to help create a future of antifragile humans.  
          </p>
          <p>Being antifragile means our students don’t break, but bend, when they encounter problems.  Students welcome problems with curiosity, tenacity, and excitement.  They’re flexible, open, and encouraged when they don’t know the answer.  And they’re ready for the problem because AI <b>knows</b> they’re ready.
          </p>
          <p>Living in the time of the unknown is more uncomfortable for our students.  They want instant answers, and when this doesn’t happen, they become dependent learners with limited attention spans.
          </p>
          <p>They shy away from the things that are complex, preferring fast, easy, and predictable.
          </p>
          <p>Which means we are living in a paradox.  </p>

          <p>We are more technologically savvy than ever before.  We have more opportunities than ever before.  But we are on the cusp of becoming lethargic thinkers, growing frustrated in the think-time needed for complex problems.
          </p>
          <p>With Alin.AI, students don’t shrink at problems but master them.  This type of learner will build a powerful future of leading global citizens.  
          </p>
          <p>Alin.AI helps create stronger thinkers for tomorrow’s problems.</p>



        </section>

      </div>
    </main>

  );
}

export default FounderPage;
