import { RegisterReducerActions, MessageState } from "./data"

const INITIAL_STATE: MessageState = {
    firstName: '',
    secondName: '',
    email: '',
    message: ''
   }
   const messageReducer = (state=INITIAL_STATE,action: RegisterReducerActions) => {
       switch(action.type){
           case '@register/change_value':
             const {inputName, inputValue}= action.payload
             return{
               ...state,
               [inputName]:inputValue
             }
           case '@reset':
            return INITIAL_STATE;
           default:
             return state;
       }
   }
   
   export { messageReducer }